import React from "react";
import handleSubmitFunc from "./ApiCalls/handleSubmitFunc";
import ApiFunc from "./ApiCalls/ApiFunc";
import Form from "./Form";
import { lookupTypes } from "../utilities/TextLookups";
import ErrorCard from "./ErrorCard";
import { faEnvelope, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect } from "react-router";
import Agreement from "./Agreement";
import { withAlert } from "react-alert";
import Loader from "./Loader";
import { initAnura, recaptchaExecute } from "../utilities/externalScripts";
import { getParamFromUrl } from "../utilities/urlUtilities";
import { trackContent } from "../utilities/tracking";
import { local, session } from "../utilities/storage";
import PasswordStrengthBar from "react-password-strength-bar";
import { getCookie } from "../utilities/cookies";
import { isSafari } from "../utilities/clientDetect";

class SignUpForm extends React.Component {
	_isMounted = false;

	constructor (props) {
		super(props);

		const user = {};
		const fname = getParamFromUrl("fname", true);
		if (fname) {
			user.first_name = fname;
		}

		const lname = getParamFromUrl("lname", true);
		if (lname) {
			user.last_name = lname;
		}
		const email = getParamFromUrl("email", true);
		if (email) {
			user.email = email;
		}

		this.state = {
			"requirements": {},
			"user": user,
			"redirect": null,
			"showAgreement": false,
			"submitting": false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange (name, value) {
		this.setState(prevState => ({
			user: {
				...prevState.user,
				[name]: value
			}
		}));
	}

	signedUpScripts () {
		const { email } = this.state.user;
		const userUuid = local.getItem("user_uuid");

		initAnura();

		if (window.location.hostname === "localhost") {
			return;
		}

		if (session.getItem("source") === "Google") {
			// Push signup to GTM
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				"event": "conversion",
				"email": email
			});
		}

		// Event snippet for Website lead conversion page
		// gtag("event", "conversion", { "send_to": "AW-10776583319/_uB0CNuAyvUCEJe51pIo" });

		if (!local.getItem("fb_conversion") && session.getItem("source") === "facebook") {
			// Facebook signup event
			console.log("Facebook track signup");
			fbq("track", "SignUp");
			local.setItem("fb_conversion", true);
			handleSubmitFunc(null, "user", "PATCH", userUuid, true, { "fb_conversion": true });
		}

		// TikTok tracking
		if (session.getItem("source") === "tiktokMH") {
			ttq.track("SubmitForm");
		}

		// microsoft ads tracking
		window.uetq = window.uetq || [];
		window.uetq.push("event", "Just-Sign-Up", {});

		// Snap pixel
		if (session.getItem("source") === "snapchat") {
			(function(e, t, n) {
				if (e.snaptr) return;
				var a = e.snaptr = function() {a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);};
				a.queue = [];
				var s = "script";
				var r = t.createElement(s);
				r.async = !0;
				r.src = n;
				var u = t.getElementsByTagName(s)[0];
				u.parentNode.insertBefore(r, u);
			})(window, document,
				"https://sc-static.net/scevent.min.js");
			snaptr("init", "3be89975-b9a2-48d3-91bd-17fa63b7e402", {
				"user_email": email
			});
			snaptr("track", "SIGN_UP");
		}

		//trade tracker iframe
		let iframe = document.createElement("iframe");
		let url = "https://tl.tradetracker.net/?cid=36237&pid=57156&data=&type=1&tid=%%USERID%%&tam=0&qty=0&eml=&descrMerchant=&descrAffiliate=%%AFFID%%";
		url = url.replace("%%USERID%%", userUuid);
		url = url.replace("%%AFFID%%", "Tradetracker");
		iframe.src = url;
		iframe.width = 1;
		iframe.height = 1;
		document.body.appendChild(iframe);

		// Talent pixel
		let pixel = document.createElement("img");
		pixel.src = "https://www.talent.com/tracker/img-pixel.php?tracker=cashback-uk";
		pixel.width = 1;
		pixel.height = 1;
		document.body.appendChild(pixel);

	}

	handleSubmit (event) {
		event.preventDefault();
		this.setState({ submitting: true });
		let stateUserFields = this.state.user;

		const welcomeBonusUuid = getCookie("welcome_bonus_uuid");
		if (welcomeBonusUuid) {
			stateUserFields["welcome_bonus_uuid"] = welcomeBonusUuid;
		}

		if (this.state.user.password !== this.state.user.confirm_password) {
			this.props.alert.error("Those passwords do not match. Please try again.");
			this.setState({ submitting: false });

			return;
		}

		recaptchaExecute(recaptchaToken => {
			if (recaptchaToken) {
				stateUserFields.recaptcha_token = recaptchaToken;
			}

			if (this.props.referredByUserUuid) {
				stateUserFields.referred_by_user_uuid = this.props.referredByUserUuid;
			}

			handleSubmitFunc(stateUserFields, "user", "POST", "create-by-email",
				true, null,
				data => {
					this.setState({ submitting: false });

					if (data.token) {
						local.setItem("user_token", data.token.access_token);
						local.setItem("user_uuid", data.user.uuid);
						local.setItem("user_email", data.user.email);

						if (this.props.trackAs) {
							trackContent(this.props.trackAs);
						}

						if (data.user?.data?.fb_conversion) {
							local.setItem("fb_conversion", true);
						}

						this.signedUpScripts();

						this.props.alert.success("Signed up successfully",
							{
								timeout: 1500,
								onClose: () => {
									this.setState({ showAgreement: true });
								}
							});
					} else {
						this.props.alert.error("Sign up failed: " + data.message);
					}

				}, message => {
					console.error(message);
					this.props.alert.error(message.toString());
					this.setState({ submitting: false });
				});
		});
	}

	componentDidMount () {
		this._isMounted = true;

		ApiFunc(`user/create-by-email/post-requirements`)
			.then(response => response.json())
			.then(data => {
				if (this._isMounted) {
					let requirements = data.requirements;
					const order = {
						"first_name": null,
						"last_name": null
					};
					// re-order object keys to match above
					requirements = Object.assign(order, requirements);
					this.setState({ requirements: requirements });
				}
			})
			.catch(error => {
				console.error(error);
			});
	}

	componentWillUnmount () {
		this._isMounted = false;
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (prevProps.prePop !== this.props.prePop) {
			this.setState({ user: { ...this.state.user, ...this.props.prePop } });
		}

	}

	render () {
		let { requirements, user, message, redirect, showAgreement, submitting } = this.state;
		let toDisplay;

		if (requirements) {
			if (requirements.track_uuid) {
				requirements.track_uuid.type = "hidden";
				user.track_uuid = local.getItem("track_uuid");
				user.ip = session.getItem("ip");
			}

			if (requirements.username) {
				requirements.username.type = "hidden";
			}

			if (requirements.recaptcha_token) {
				requirements.recaptcha_token.type = "hidden";
			}

			if (requirements.welcome_bonus_uuid) {
				requirements.welcome_bonus_uuid.type = "hidden";
			}

			if (requirements.referred_by_user_uuid) {
				requirements.referred_by_user_uuid.type = "hidden";
			}

			if (requirements.ip) {
				requirements.ip.type = "hidden";
			}

			if (requirements.first_name) {
				requirements.first_name.icon = <FontAwesomeIcon icon={faUser} className="input-icon" />;
				requirements.first_name.autoComplete = "given-name";
				requirements.first_name.placeholder = "First Name";
			}

			if (requirements.last_name) {
				requirements.last_name.icon = <FontAwesomeIcon icon={faUser} className="input-icon" />;
				requirements.last_name.autoComplete = "family-name";
				requirements.last_name.placeholder = "Last Name";
			}

			if (requirements.email) {
				requirements.email.icon = <FontAwesomeIcon icon={faEnvelope} className="input-icon" />;
				requirements.email.autoComplete = "email";
				requirements.email.placeholder = "E-mail address";
			}

			if (requirements.password) {
				requirements.password.icon = <FontAwesomeIcon icon={faLock} className="input-icon" />;

				requirements.password.autoComplete = "new-password";
				requirements.password.placeholder = "Password";
				if (isSafari()) {
					// modify fields to remove new-password autocomplete value
					requirements.password.autoComplete = "password";
				}

				requirements.confirm_password = Object.create(requirements.password);
				requirements.confirm_password.placeholder = "Confirm Password";
			}

			toDisplay = (
				<>
					<Form
						className={this.props.className ?? ""}
						handleSubmit={this.handleSubmit}
						submitting={submitting || this.props.disabled}
						onFieldChange={this.handleChange}
						formType={lookupTypes.USER}
						requirements={requirements}
						fieldValues={user}
						hideLabels={true}
						hideRequiredLabels={true}
						submitText={this.props.submitText || "Join now for FREE!"}
						insertAfter={{
							"password": <PasswordStrengthBar
								className="password-strength-bar"
								password={user.password}
								minLength={6}
								barColors={["#ddd", "#000", "#eccd74", "#2b90ef", "#25c281"]}
							/>
						}}
					>
						{this.props.children}
					</Form>
					<Agreement userAgreements={showAgreement ? null : undefined} onComplete={() => {
						this.setState({
							showAgreement: false,
							redirect: <Redirect to="/tasks" />
						});
					}} />
					<Loader visible={submitting} />
				</>
			);
		} else {
			toDisplay = <ErrorCard message={message} />;
		}

		return redirect !== null ? redirect : toDisplay;
	}
}

export default withAlert()(SignUpForm);