import React, { Component } from "react";
import SignUpForm from "./SignUpForm";
import { withAlert } from "react-alert";
import { initRecaptcha } from "../utilities/externalScripts";
import { checkGeo } from "./ApiCalls/geo";
import { getParamFromUrl } from "../utilities/urlUtilities";
import { local } from "../utilities/storage";

class SignUpBox extends Component {
	constructor (props) {
		super(props);

		this.state = {
			geoOk: null
		};
	}

	componentDidMount () {
		initRecaptcha();

		const geoOk = local.getItem("okoeg");
		if (geoOk !== null) {
			this.setState({ geoOk: geoOk });
		} else {
			checkGeo()
				.then(geoOk => {
					this.setState({ geoOk });
					local.setItem("okoeg", geoOk);
				})
				.catch(error => {
					console.error("Error checking geo", error);
				});
		}
	}

	render () {
		const closeBtn = <span className="modal-close"
							   tabIndex="0"
							   role="button"
							   aria-label="Close"
							   onKeyUp={e => {
								   if (e.key === "Enter" || e.key === "Escape") {
									   this.props.handleClose();
								   }
							   }}
							   onClick={this.props.handleClose}>×</span>;

		const linkData = this.props.referredByUserUuid ? `referredByUserUuid=${this.props.referredByUserUuid}` : "";
		const h2 = getParamFromUrl("h2", true) ?? null;
		return (
			<div
				className={(this.props.show ? "overlay" : "overlay hide") + (this.props.hideClose ? " allow-clicks" : "")}>
				<div className="modal signup">
					<div className="signup-title">
						<h2>{h2 ? h2 : "Join today for FREE and start earning!"}</h2>
						{this.props.hideClose ? "" : closeBtn}
					</div>

					<div className="signup-form">
						<SignUpForm disabled={!this.state.geoOk}
									referredByUserUuid={this.props.referredByUserUuid}
									trackAs={this.props.trackAs}
									prePop={this.props.prePop}
						>
							<div className="signup-terms_box">
								<p className="signup-terms">The information you provide to us, Submission
									Technology,
									Ltd.,
									is used to create a Cashback account for you so you can track your progress
									and we can pay you when necessary. By registering and entering you confirm
									that you have read and agree to our <a href="/terms-and-conditions">Terms and
										Conditions</a> &amp; <a href="/privacy-notice">Privacy Notice</a> which
									governs how your information will be processed.
									<br /><br />UK RESIDENTS OVER 18 ONLY</p>
							</div>

						</SignUpForm>

						<p className="signup-login">
							<a href="/login" onClick={(e) => {
								e.preventDefault();
								this.props.switchModal("login");
							}}
							   className="login-link">Already have an account?</a>
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default withAlert()(SignUpBox);