import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRouting, InitRouting } from "./utilities/AppRouting";
import * as Sentry from "@sentry/react";
import "./scss/all.scss";
import { getMyIP, updateTracking } from "./utilities/tracking";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./Components/alert-template";
import { addTagsToHead } from "./utilities/addTagsToHead";
import ErrorBoundary from "./ErrorBoundary";
import { session } from "./utilities/storage";
import { startOneSignal } from "./utilities/oneSignal";
import ScrollToTop from "./Components/ScrollToTop";

// noinspection JSClassNamingConvention
class App extends Component {
	constructor (props) {
		super(props);

		const time = new Date();

		this.state = {
			lastFocus: time.getTime()
		};

		InitRouting();
	}

	componentDidMount () {
		this.loadScripts();
		window.addEventListener("focus", this.onFocus);
		window.addEventListener("blur", this.onBlur);
		window.addEventListener("beforeunload", this.handleBeforeUnload);
		if (window["splitTest"] instanceof Function) {
			splitTest();
		}

		this.storeSource();
		startOneSignal();
	}

	componentWillUnmount () {
		window.removeEventListener("focus", this.onFocus);
		window.removeEventListener("blur", this.onBlur);
		window.removeEventListener("beforeunload", this.handleBeforeUnload);

		updateTracking();
	}

	handleBeforeUnload = () => {
		updateTracking();
	};

	storeSource () {
		const params = new URLSearchParams(location.search);
		const source = params.get("utm_source");

		if (!session.getItem("source") && source) {
			session.setItem("source", source);
		}
	}

	onFocus = () => {
		const time = new Date();
		if (this.state.lastFocus < time.getTime() - 1000 * 60) {
			this.setState({ lastFocus: time.getTime() });
			getMyIP();
		}
	};

	onBlur = () => {
		// session.removeItem("ip");
	};

	loadScripts = () => {
		if (!location.pathname.startsWith("/loading") &&
			!navigator.userAgent.includes("Lighthouse") &&
			!(window.location.hostname === "localhost")) {

			// noinspection LongLine
			const scripts = [
				// {
				// 	// Global site tag (gtag.js) - Google Analytics
				// 	src: "https://www.googletagmanager.com/gtag/js?id=UA-203441994-1",
				// 	async: true
				// },
				// {
				// 	innerHTML: "window.dataLayer = window.dataLayer || [];" +
				// 		"function gtag () {dataLayer.push(arguments);}" +
				// 		"gtag('js', new Date());" +
				// 		"gtag('config', 'UA-203441994-1', { cookie_flags: 'SameSite=None;Secure' });"
				// },
				// {
				// 	// Google Ads: 10776583319
				// 	src: "https://www.googletagmanager.com/gtag/js?id=AW-10776583319",
				// 	async: true
				// },
				// {
				// 	innerHTML: "window.dataLayer = window.dataLayer || []; function" +
				// 		" gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10776583319');"
				// },
				{
					// TikTok pixel
					innerHTML: "!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=[\"page\",\"track\",\"identify\",\"instances\",\"debug\",\"on\",\"off\",\"once\",\"ready\",\"alias\",\"group\",\"enableCookie\",\"disableCookie\"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i=\"https://analytics.tiktok.com/i18n/pixel/events.js\";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement(\"script\");n.type=\"text/javascript\",n.async=!0,n.src=i+\"?sdkid=\"+e+\"&lib=\"+t;e=document.getElementsByTagName(\"script\")[0];e.parentNode.insertBefore(n,e)};ttq.load('CCCQAOBC77U03NFKAUMG');ttq.page(); }(window, document, 'ttq');"
				},
				{
					// Facebook pixel
					innerHTML: "!function(f,b,e,v,n,t,s)" +
						"{if(f.fbq)return;n=f.fbq=function(){n.callMethod?" +
						"n.callMethod.apply(n,arguments):n.queue.push(arguments)};" +
						"if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';" +
						"n.queue=[];t=b.createElement(e);t.async=!0;" +
						"t.src=v;s=b.getElementsByTagName(e)[0];" +
						"s.parentNode.insertBefore(t,s)}(window,document,'script'," +
						"'https://connect.facebook.net/en_US/fbevents.js');" +
						"fbq('init', '4440249542689902');" +
						"fbq('track', 'PageView');"
				}
			];

			addTagsToHead(scripts);
		}

		if (location.pathname.startsWith("/fb")) {
			// noinspection LongLine
			const scripts = [{
				// Hotjar Tracking Code for https://www.cashback.co.uk/
				innerHTML: "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};" +
					" h._hjSettings={hjid:2580303,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
			}];
			addTagsToHead(scripts);
		}
	};

	render () {
		const options = {
			position: positions.TOP_CENTER,
			timeout: 5000,
			offset: "50px",
			transition: transitions.FADE
		};

		return (
			<ErrorBoundary>
				<AlertProvider template={AlertTemplate} {...options}>
					<Router {...history}>
						<ScrollToTop />
						<AppRouting />
					</Router>
				</AlertProvider>
			</ErrorBoundary>
		);
	}
}

export default Sentry.withProfiler(App);