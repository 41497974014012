import React, { Component } from "react";
import PropTypes from "prop-types";

class QuestionFreq extends Component {
	render () {
		return (
			<>
				<p className="survey-question">{"Q" + (this.props.questionNumber || 4) +
					" of " + (this.props.totalQuestions || 4) + ": In addition to progress updates, tell us how often "+
					"you'd like to hear from us on the latest level offers? You can pick several..."}</p>

				<label htmlFor="box1">
					<input type="checkbox"
						   name="cbuk_survey_freq"
						   value="cbuk_survey_freq_daily"
						   id="box1"
						   onClick={this.props.onClick}
					/>
					Daily
				</label>

				<label htmlFor="box2">
					<input type="checkbox"
						   name="cbuk_survey_freq"
						   value="cbuk_survey_freq_weekly"
						   id="box2"
						   onClick={this.props.onClick}
					/>
					Weekly
				</label>

				<label htmlFor="box3">
					<input type="checkbox"
						   name="cbuk_survey_freq"
						   value="cbuk_survey_freq_monthly"
						   id="box3"
						   onClick={this.props.onClick}
					/>
					Monthly
				</label>
			</>
		);
	}
}

QuestionFreq.propTypes = {
	questionNumber: PropTypes.number,
	totalQuestions: PropTypes.number,
	onClick: PropTypes.func.isRequired
};

export default QuestionFreq;