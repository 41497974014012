import React, { Component } from "react";
import { getUserUuid, trackClick, trackLastClick } from "../../utilities/tracking";

class Loading extends Component {
	constructor (props) {
		super(props);
		document.title = "Taking you to your task...";

		const urlParams = new URLSearchParams(window.location.search);
		let link = urlParams.get("link");
		link = decodeURIComponent(link);

		this.state = {
			linkOut: link,
			tryAgain: false
		};
	}

	componentDidMount () {
		this.updateLink();

		setTimeout(() => {
			this.setState({ tryAgain: true });
		}, 5000);
	}

	updateLink () {
		const urlParams = new URLSearchParams(window.location.search);
		const ladderOfferUuid = urlParams.get("task");

		let link = urlParams.get("link");
		let clickUuid = urlParams.get("clickUuid");
		let clickTrack = urlParams.get("click_track");
		let externalId = urlParams.get("external_id");
		link = decodeURIComponent(link);

		link = link.replace("%%USERUUID%%", getUserUuid() ?? "");

		if (clickTrack) {
			// for offertoro click uuid
			trackClick(ladderOfferUuid, () => {
				setTimeout(() => {
					window.location = link;
				}, 2000);
			}, clickTrack, externalId);
			return;
		}

		if (clickUuid === null) {
			trackClick(ladderOfferUuid, clickId => {
				link = link.replace("%%CLICKID%%", clickId);
				this.setState({ linkOut: link });
				setTimeout(() => {
					window.location = link;
				}, 2000);
			});
			return;
		} else {
			trackLastClick(clickUuid).then(() => {
				this.setState({ linkOut: link });
				window.location = link;
			});
		}

		this.setState({ linkOut: link });
		setTimeout(() => {
			window.location = link;
		}, 2000);

	}

	render () {
		// assume pathname is "/loading/brandname"
		let brandName = location.pathname.slice(9);
		brandName = brandName.replace(/\+/g, " ");
		brandName = decodeURIComponent(brandName);
		brandName = brandName.replace(/[^a-zA-Z0-9 ]/g, "");

		const { tryAgain } = this.state;

		return (
			<div className="loading-page">
				<img src="https://cashback-public.s3.eu-west-2.amazonaws.com/logo/favicon-228.png" alt="Cashback" />
				<p>We're generating your unique link to the <span className="brand">{brandName}</span> site for you.</p>
				<p>Please complete the task as per the instructions.</p>
				<p>Once you're done, don't forget to return to Cashback to complete more tasks.</p>
				<div className="lds-default">
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
				</div>
				<div className={tryAgain ? "" : "hide"}>
					<p>Something has gone wrong!</p>
					<button onClick={() => {location.reload();}} className="btn btn-try-again">
						Try again
					</button>
				</div>
			</div>
		);
	}
}

export default Loading;